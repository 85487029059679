<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-9 col-lg-9 col-xl-8 col-xxl-7 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center holder">
                      <div class="bg_holder">
                        <div class="background" >
                        </div>
                      </div>
                      <div class="col-12 px-4 py-3 above" v-if="isUK">
                        <div class="row m-0 justify-content-center">
                          <div class="col-10 text-center my-4">
                            <div class="heading">
                              We respect your privacy...
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            We are committed to respecting the privacy rights of all donors and visitors to our website. The following information details Al-Imdaad Foundation UK collection, protection, and use of the data we receive from donors and visitors to this website.
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
                              Our General Policy
                            </div>
                            <div>
                              Al-Imdaad Foundation UK recognizes the importance of protecting information we may collect from donors and visitors to our site. We maintain appropriate security measures to keep this information private.
                            </div>
                            <div>
                              Users can visit the site without revealing any information about themselves. If you choose to give us personal information via the Internet for the purposes of correspondence, processing a donation or subscribing to our email newsletter, then it is our intent to let you know how we will use such information.
                            </div>
                            <div>
                              Al-Imdaad Foundation UK will remove your name from our mailing list, email list or telephone solicitation list at any time, at your request. <b>We will not sell, rent or trade your email address to a third party.</b>
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
                              Email Updates
                            </div>
                            <div>
                              We use a third-party email marketing party to deliver emails. This company employs techniques for tracking open rates and click-thrus that use email address as an identifier.
                            </div>
                            <div>
                              You will be given the option to remove your name from our email list at the bottom of each email update. And, again, we will not sell, rent or trade your email address to a third party.
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
                              Credit Card Policy
                            </div>
                            <div>
                              We do not store credit card details nor do we share customer details with any 3rd parties.
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
                              Online Donations
                            </div>
                            <div>
                              Al-Imdaad Foundation UK online and offline donation processing systems comply with PCI Security Standards, a set of comprehensive requirements for enhancing payment account data security.
                            </div>
                            <div>
                              Our online donation processor hosts our secure giving pages on PCI Compliant servers and uses high-grade encryption and the industry-standard https security protocol to ensure secure transactions.
                            </div>
                            <div>
                              Independent external and internal audits ensure the privacy, security and appropriate processing of your information on our site.
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
                              Use of Third-Party Software and Advertising Services
                            </div>
                            <div>
Web browser tell us the type of computer and operating system our site visitors are using. We're also able to learn the domain where our visitors come from. We analyse this data for trends and insights.
                            </div>
                            <div>
We also use Google Analytics, Quantcast and Clicktale to examine data about our site visitors. These services tell us information about technical aspects of our visitors' computers and some demographic information. We use this information to improve our content and structure, leading to a better user experience.
                            </div>
                            <div>
We do not receive or request any personally identifiable information from you unless you provide it.
                            </div>
                            <div>
We use third-party advertising companies to serve ads when you visit our Website. These companies may use information (not including your name, address email address or telephone number) about your visits to this and other Web sites in order to provide advertisements about goods and services of interest to you.
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <div  class="bold">
If you would have additional questions about our privacy policy, please feel free to contact us via email at info@alimdaad.co.uk or 01254 698771
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12 px-4 py-3 above" v-else>
                        <div class="row m-0 justify-content-center">
                          <div class="col-10 text-center my-4">
                            <div class="heading">
                              We respect your privacy...
                            </div>
                          </div>
                          <div class="col-11 mb-4">
                            <p>
                                <strong> Al-Imdaad Foundation </strong> respects the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application or visit our Website. Please read this Privacy Policy carefully.
                                <strong>IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION OR REGISTER ON OUR WEBSITE.</strong>
                            </p>
                            <p>
                                We reserve the right to make changes to this Privacy Policy. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, and to have accepted the changes in any revised Privacy Policy by continued use of the Application or Website after the date such revised Privacy Policy is posted. </p>
                            <p>
                                This Privacy Policy does not apply to the third-party online/mobile stores/payment gateways from which you install the Application or make payments, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.
                            </p>

                            <h5><strong>Collection Of Your Information</strong></h5>

                            <p>
                                We may collect information about you in a variety of ways. The information we may collect via our application or Website depends on the content and materials you use, and includes:</p>

                            <h5><strong>Personal Data</strong></h5>
                            <p>
                                Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the Application or Website, such as signing up, making a donation, sending feedback, and making queries. If you choose to share data about yourself via your AIF Donor profile, or other interactive areas of the Application or Website, please be advised that all data you disclose in these areas will be accessible to the Al-Imdaad Foundation.
                            </p>
                            <h5><strong>Derivative Data</strong></h5>
                            <p>
                                Information our servers automatically collect when you access the Application or Website, such as your native actions that are integral to the Application or Website, including app or Website usage patterns, donation decisions, sharing project pages, as well as other interactions with the Application or Website via server log files.
                            </p>
                            <h5><strong>Financial Data</strong></h5>
                            <p>
                              Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you make donations or request information about our services from the Application or Website. We store only very limited, financial information that we collect. Otherwise, all financial information is stored by our payment processor, <a href="https://www.wirecard.com/datenschutzbestimmungen/" target="_blank">Wire Card</a>, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                            </p>

                            <h5><strong>Mobile Device Access (Application only)</strong></h5>
                            <p>
                                We may request access or permission to certain features from your mobile device, including your mobile device’s calendar, camera, reminders, SMS messages, social media accounts, storage and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                            </p>

                            <h5><strong>Mobile Device Data (Application only)</strong></h5>
                            <p>
                                Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide. This can help us enhance the app and improve usability across a range of devices.
                            </p>

                            <h5><strong>Push Notifications</strong></h5>
                            <p>
                                We may request to send you push notifications regarding featured projects, Zakaat reminders, Sadaqah reminders etc. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                            </p>

                            <h5><strong>Third-Party Data </strong></h5>
                            <p>
                              Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant permission to access this information.
                            </p>

                            <h5><strong>Use of Your Information</strong></h5>
                            <p>
                              Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application or Website to:
                            </p>
                            <ol>
                                <li> Administer donations</li>
                                <li> Compile anonymous statistical data and analysis for use internally or with third parties.</li>
                                <li> Create and manage your AIF User account.</li>
                                <li> Deliver targeted marketing, project suggestions, newsletters, and other information regarding promotions and the Application, to you.</li>
                                <li> Email you regarding your AIF account or donations, including receipts, feedback etc.</li>
                                <li> Fulfil and manage donations, debit orders, sponsorships, 18A certificates and other transactions related to your donations.</li>
                                <li> Generate a personal profile about you to make future visits to the Application or Website more personalized.</li>
                                <li> Increase the efficiency and operation of the Application or Website.</li>
                                <li> Monitor and analyse usage and trends to improve your experience with the Application or Website.</li>
                                <li> Notify you of updates to the Application.</li>
                                <li> Offer new projects, services, mobile applications, and/or recommendations to you.</li>
                                <li> Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                                <li> Process donations and refunds</li>
                                <li> Request feedback and contact you about your use of the Application or Website</li>
                                <li> Resolve disputes and troubleshoot problems.</li>
                                <li> Respond to project and customer service requests.</li>
                                <li> Send you a newsletter.</li>
                                <li> Encourage support through sharing, liking etc</li>
                            </ol>

                            <h5><strong>DISCLOSURE OF YOUR INFORMATION</strong></h5>
                            <p>
                              We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                            </p>

                            <h5><strong>By Law or to Protect Rights </strong></h5>
                            <p>
                              If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
                            </p>

                            <h5><strong>Third-Party Service Providers</strong></h5>
                            <p>
                              We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                            </p>

                            <h5><strong>Affiliates</strong></h5>
                            <p>
                              We may share your information with our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include our sister organizations, including Friends of the Suffering South Africa, that we control or that are under common control with us.
                            </p>
                            <h5 class="mb-3"><strong>TRACKING TECHNOLOGIES</strong></h5>
                            <h5><strong>Cookies and Web Beacons</strong></h5>
                            <p>
                              We may use cookies, web beacons, tracking pixels, and other tracking technologies to help customize and improve your experience. When you access Application or Website, your personal information is not collected using tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies but be aware that such action could affect the availability and functionality of the Application or Website. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
                            </p>
                            <h5><strong>Internet-Based Advertising</strong></h5>
                            <p>
                              Additionally, we may use third-party software to serve ads on the Application, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us. For more information about opting-out of interest-based ads, visit the <a href="https://optout.networkadvertising.org/" target="_blank">Network Advertising Initiative Opt-Out Tool</a> or <a href="http://www.aboutads.info/choices/" target="_blank">Digital Advertising Alliance Opt-Out Tool.</a>
                            </p>
                            <h5><strong>Website Analytics </strong></h5>
                            <p>
                              We may also partner with selected third-party vendors, such as Adobe Analytics and Google Analytics, to allow tracking technologies and remarketing services using first party cookies and third-party cookies, to, among other things, analyse and track users’ use of the Application or Website, determine the popularity of certain content, and better understand online activity. By accessing the Application or Website, you consent to the collection and use of your information by these service providers. You are encouraged to review their privacy policies and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can install and/or update your settings for one of the following:
                            </p>
                            <ul>
                                <li><a href="https://www.adobe.com/privacy/opt-out.html" target="_blank">[Adobe Privacy Choices Page]</a></li>
                                <li><a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">[Google Analytics Opt-Out Plugin]</a></li>
                                <li><a href="https://www.google.com/settings/u/0/ads/authenticated?hl=en" target="_blank">[Google Ads Settings Page]</a></li>
                            </ul>
                            <p>
                              You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
                            </p>
                            <h5><strong>SECURITY OF YOUR INFORMATION</strong></h5>
                            <p>
                              We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                            </p>
                            <h5><strong>POLICY FOR CHILDREN</strong></h5>
                            <p>
                              We do not knowingly solicit information from, or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.
                            </p>

                            <h5 class="mb-3"><strong>OPTIONS REGARDING YOUR INFORMATION</strong></h5>
                            <h5><strong>Account Information</strong></h5>
                            <p>
                              You may at any time review or change the information in your account or terminate your account by:
                            </p>
                            <ul>
                                <li>Logging into your account settings and updating your account</li>
                                <li>Contacting us using the contact information provided below</li>
                                <li>0861786243 Ext 401</li>
                            </ul>
                            <p>
                              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                            </p>
                            <h5><strong>Emails and Communications</strong></h5>
                            <p>
                              If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by
                            </p>
                            <ul>
                                <li>Noting your preferences at the time you register your account with the Application or Website</li>
                                <li>Logging into your account settings and updating your preferences.</li>
                                <li>Contacting us using the contact information provided below</li>
                            </ul>
                            <p>
                              If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
                            </p>
                            <h5><strong>CONTACT US</strong></h5>
                            <p>
                              If you have questions or comments about this Privacy Policy, please contact us at:
                            </p>
                            <ul>
                                <li>Name: Al-Imdaad Foundation</li>
                                <li>Address: No. 5 Smally’s Court, 204 Alfred Street, Estcourt, South Africa</li>
                                <li>Postal Code: 3310</li>
                                <li>Phone Number: 0861786243</li>
                                <li>Email: queries@alimdaad.com</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'Donations Policy',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 6px 7px 26px 0px rgb(0 0 0 / 22%);

}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.card_title {
  font-family: "quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
  color: var(--green-color-dark);
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 20px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  background-image: url(../../assets/images/bg.png);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  border-radius: 20px;
  border-top-right-radius: 20px;
  background-size: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
a, a:visited {
  text-decoration: underline;
  color: var(--green-color-light);
}
a:hover {
  color: var(--green-color-lighter);
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
}
</style>
