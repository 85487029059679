<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-9 col-lg-9 col-xl-8 col-xxl-7 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center holder">
                      <div class="bg_holder">
                        <div class="background" >
                        </div>
                      </div>
                      <div class="col-12 px-4 py-3 above">
                        <div class="row m-0 justify-content-center" v-if="isUK">
                          <div class="col-10 text-center my-4">
                            <div class="heading">
                              100% Donations Policy
                            </div>
                          </div>
                          <div class="col-11 mb-3">
                            It is important to mention that Al-Imdaad Foundation UK operate a 100% donation policy for Zakaat and Sadaqaah. This means that every penny you donate goes directly to the deserved causes and makes a difference in the lives of the most needy orphans, widows and destitute around the world. Any donations specified as Lillah either by country or project will be utilised for implementation and delivery of that project, specific to that country.
                          </div>
                          <div class="col-11 mb-3">
                            We have a separate fund to cover our UK administration, fundraising and marketing costs. This fund is supported through business sponsorships, tax reclaim from gift aid and donations specified for administration purposes. Tax reclaimed from the UK government's Gift Aid scheme allows taxpayers to increase the value of their donation by 25%. This additional money and any unspecified Lillah donations will be allocated to the charity's administration fund to support the running and delivery of all our global projects.
                          </div>
                          <div class="col-11 mb-3">
                            In line with the ethos of Al-Imdaad Foundation UK the costs of all our events held in the UK is borne by our business sponsors and supporters. Not a single penny from Al-Imdaad Foundation UK goes towards these events. In fact it is an obligation that every penny of the donation received is fully accountable and we shall always endeavour to honour and fulfil the expectations of our donors.
                          </div>
                          <div class="col-11 mb-3">
                            We also receive interest money and 'purification money' from Islamic financial institutions and this is disposed in accordance with Shariah rules and pronouncements.
                          </div>
                          <div class="col-11 mb-3">
                            We request all our donors and supporters to contribute towards our administration fund to enable us to maintain our commitment to the 100% donation policy.
                          </div>
                          <div class="col-11 mb-3">
                            Al-Imdaad Foundation UK is affiliated to the Fundraising Standards Board and the Institute of Fundraising as well the Charities commission.
                          </div>
                        </div>
                        <div class="row m-0 justify-content-center" v-else>
                          <div class="col-10 text-center my-4">
                            <div class="heading">
                              100% Donations Policy
                            </div>
                          </div>
                          <div class="col-11 mb-3">
                            It is important to mention that Al-Imdaad Foundation operate a 100% donation policy for Zakaat and Sadaqaah. This means that every penny you donate goes directly to the deserved causes and makes a difference in the lives of the most needy orphans, widows and destitute around the world. Any donations specified as Lillah either by country or project will be utilised for implementation and delivery of that project, specific to that country.
                          </div>
                          <div class="col-11 mb-3">
                            We have a separate fund to cover our administration, fundraising and marketing costs. This fund is supported through business sponsorships, tax reclaim from gift aid and donations specified for administration purposes. Tax reclaimed from the government's Gift Aid scheme allows taxpayers to increase the value of their donation by 25%. This additional money and any unspecified Lillah donations will be allocated to the charity's administration fund to support the running and delivery of all our global projects.
                          </div>
                          <div class="col-11 mb-3">
                            In line with the ethos of Al-Imdaad Foundation the costs of all our events held in the is borne by our business sponsors and supporters. Not a single penny from Al-Imdaad Foundation goes towards these events. In fact it is an obligation that every penny of the donation received is fully accountable and we shall always endeavour to honour and fulfil the expectations of our donors.
                          </div>
                          <div class="col-11 mb-3">
                            We also receive interest money and 'purification money' from Islamic financial institutions and this is disposed in accordance with Shariah rules and pronouncements.
                          </div>
                          <div class="col-11 mb-3">
                            We request all our donors and supporters to contribute towards our administration fund to enable us to maintain our commitment to the 100% donation policy.
                          </div>
                          <div class="col-11 mb-3">
                            Al-Imdaad Foundation is affiliated to the Fundraising Standards Board and the Institute of Fundraising as well the Charities commission.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'Donations Policy',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.card_title {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
  color: var(--green-color-dark);
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 20px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  background-image: url(../../assets/images/bg.png);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  border-radius: 20px;
  border-top-right-radius: 20px;
  background-size: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
}
</style>
