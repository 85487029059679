<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-9 col-lg-9 col-xl-8 col-xxl-7 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center holder">
                      <div class="bg_holder">
                        <div class="background" >
                        </div>
                      </div>
                      <div class="col-12 px-4 py-3 above">
                        <div class="row m-0 justify-content-center">
                          <div class="col-11 mb-4">
                            <h5><strong>Description of services</strong></h5>

                            <p>The Al-Imdaad Foundation is a non-profit humanitarian aid relief organisation (NGO) registered in the Republic of South Africa, dedicated to providing humanitarian services in crisis and non-crisis situations to most needy orphans, widows and destitute, irrespective of race, religion, culture, creed and geographical boundary.</p>

                            <h5><strong>Return; Refund and cancellation policies</strong></h5>
                            <p>
                              We will refund donations to donors in full within 30 days.
                            </p>
                            <h5><strong>Transaction currency</strong></h5>
                            <p>
                              The merchant outlet country at the time of presenting payment options to the cardholder is South Africa. Transaction currency is South African Rand (ZAR).
                            </p>
                            <h5><strong>Governing law</strong></h5>
                            <p>
                              This website is governed by the laws of South Africa.
                            </p>

                            <h5><strong>Country of domicile</strong></h5>
                            <p>
                              Al-Imdaad chooses South Africa as its domicilium citandi et executandi for all purposes.
                            </p>

                            <h5><strong>Company information</strong></h5>
                            <p>
                              This website is run by the Al-Imdaad Foundation based in South Africa trading as Al-Imdaad Trust.
                            </p>

                            <h5><strong>Customer Privacy policy</strong></h5>
                            <p>
                              The Al-Imdaad Foundation shall take all reasonable steps to protect the personal information of users. For the purpose of this clause, "personal information" shall be defined as detailed in the Promotion of Access to Information Act 2 of 2000 (PAIA). The PAIA may be downloaded from: Current Legislation.
                            </p>

                            <h5><strong>Payment options accepted</strong></h5>
                            <p>
                              Payment may be made via Visa, MasterCard, Diners or American Express Cards or by bank transfer into the Al-Imdaad Foundation’s bank account, the details of which are on this website.
                            </p>

                            <h5><strong>Card acquiring and security</strong></h5>
                            <p>
                              Card transactions will be acquired for the Al-Imdaad Foundation via PayFast and PayGate (Pty) Ltd who are approved payment gateways for all South African Acquiring Banks. DPO PayGate uses the strictest form of encryption, namely Secure Socket Layer 3 (SSL3) and no card details are stored on the website. Users may go to Payment Gateway South Africa - DPO South Africa to view their security certificate and security policy.
                            </p>
                            <h5><strong>Customer details separate from card details</strong></h5>
                            <p>
                              Customer details will be stored by the Al-Imdaad Foundation separately from card details which are entered by the client on PayFast's and DPO PayGate's secure sites. For more detail on DPO PayGate refer to Payment Gateway South Africa - DPO South Africa.
                            </p>

                            <h5><strong>Variation</strong></h5>
                            <p>
                              The Al-Imdaad Foundation may, in its sole discretion, change this agreement or any part thereof at any time without notice.
                            </p>

                            <h5><strong>Al-Imdaad Foundation's contact details</strong></h5>
                            <p>
                              Our physical address, postal address, email and telephone numbers are listed at the bottom of this page.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'Donations Policy',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 6px 7px 26px 0px rgb(0 0 0 / 22%);

}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.card_title {
  font-family: "quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
  color: var(--green-color-dark);
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 20px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  background-image: url(../../assets/images/bg.png);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  border-radius: 20px;
  border-top-right-radius: 20px;
  background-size: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
}
</style>
