<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-9 col-lg-9 col-xl-8 col-xxl-7 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center holder">
                      <div class="bg_holder">
                        <div class="background" >
                        </div>
                      </div>
                      <div class="col-12 px-4 py-3 above">
                        <div class="row m-0 justify-content-center py-3" v-if="isUK">
                          <div class="col-11 mb-3">
                            The information contained in this website is for general information purposes only. The information is provided by Al-Imdaad Foundation UK and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                          </div>
                          <div class="col-11 mb-3">
                            In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                          </div>
                          <div class="col-11 mb-3">
                            Through this website you are able to link to other websites affiliated to Al-Imdaad Foundation UK. However, we have no control over the nature, content and availability of those sites. The inclusion of any external website links to www.alimdaad.co.uk does not necessarily imply a recommendation or endorse the views expressed within them. Al-Imdaad Foundation UK is not responsible for the content of any external internet websites.
                          </div>
                          <div class="col-11 mb-3">
                            Every effort is made to keep the website up and running smoothly. However, Al-Imdaad Foundation UK takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                          </div>
                          <div class="col-11 mb-3">
                            Al-Imdaad Foundation UK does not endorse or appoint any representative to carry out door to door collections. Any such individual found to be fundraising at your doorstep is not affiliated with the charity and is committing fraudulent activity.
                          </div>
                        </div>
                        <div class="row m-0 justify-content-center py-3" v-else>
                          <div class="col-11 mb-3">
                            The information contained in this website is for general information purposes only. The information is provided by Al-Imdaad Foundation and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                          </div>
                          <div class="col-11 mb-3">
                            In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                          </div>
                          <div class="col-11 mb-3">
                            Through this website you are able to link to other websites affiliated to Al-Imdaad Foundation. However, we have no control over the nature, content and availability of those sites. The inclusion of any external website links to www.alimdaad.com does not necessarily imply a recommendation or endorse the views expressed within them. Al-Imdaad Foundation is not responsible for the content of any external internet websites.
                          </div>
                          <div class="col-11 mb-3">
                            Every effort is made to keep the website up and running smoothly. However, Al-Imdaad Foundation takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                          </div>
                          <div class="col-11 mb-3">
                            Al-Imdaad Foundation does not endorse or appoint any representative to carry out door to door collections. Any such individual found to be fundraising at your doorstep is not affiliated with the charity and is committing fraudulent activity.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'Donations Policy',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.card_title {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
  color: var(--green-color-dark);
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 20px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  background-image: url(../../assets/images/bg.png);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.97) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  border-radius: 20px;
  border-top-right-radius: 20px;
  background-size: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
}
</style>
